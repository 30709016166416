exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-md-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/md/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-md-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-wagen-1-js": () => import("./../../../src/pages/wagen1.js" /* webpackChunkName: "component---src-pages-wagen-1-js" */),
  "component---src-pages-wagen-2-js": () => import("./../../../src/pages/wagen2.js" /* webpackChunkName: "component---src-pages-wagen-2-js" */),
  "component---src-pages-wagen-3-js": () => import("./../../../src/pages/wagen3.js" /* webpackChunkName: "component---src-pages-wagen-3-js" */),
  "component---src-pages-wagen-4-js": () => import("./../../../src/pages/wagen4.js" /* webpackChunkName: "component---src-pages-wagen-4-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

